import React from 'react';
import { Helmet } from 'react-helmet';

export default function FourOhFour() {
  return (
    <>
      <Helmet title={'konjo | 404'} />
      <div
        style={{
          textAlign: 'center',
          padding: '8.4rem 3.6rem 0',
          maxWidth: 720,
          margin: '0 auto',
        }}
      >
        <h1>404</h1>
        <p>Oops, that's doesn't exist.</p>
      </div>
    </>
  );
}
